@font-face {
  font-family: 'Gentlement-Reg';
  src: url('./fonts/Gentleman/Regular/gentleman-600-regular-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gentleman/Regular/gentleman-600-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Gentleman/Regular/gentleman-600-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Gentleman/Regular/gentleman-600-regular-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('./fonts/Gentleman/Regular/gentleman-600-regular-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Gentleman/Regular/gentleman-600-regular-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gentlement-Med';
  src: url('./fonts/Gentleman/Medium/gentleman-700-medium-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gentleman/Medium/gentleman-700-medium-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
   url('./fonts/Gentleman/Medium/gentleman-700-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
   url('./fonts/Gentleman/Medium/gentleman-700-medium-webfont.woff') format('woff'), /* Pretty Modern Browsers */
   url('./fonts/Gentleman/Medium/gentleman-700-medium-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
   url('./fonts/Gentleman/Medium/gentleman-700-medium-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
    

@font-face {
  font-family: 'Gentlement-Brook';
  src: url('./fonts/Gentleman/Brook/gentleman-500-book-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gentleman/Brook/gentleman-500-book-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Gentleman/Brook/gentleman-500-book-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('./fonts/Gentleman/Brook/gentleman-500-book-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Gentleman/Brook/gentleman-500-book-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Gentlement-Light';
  src: url('./fonts/Gentleman/Light/gentleman-light-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gentleman/Light/gentleman-light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
      url('./fonts/Gentleman/Light/gentleman-light-webfont.woff') format('woff'), /* Pretty Modern Browsers */
      url('./fonts/Gentleman/Light/gentleman-light-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
      url('./fonts/Gentleman/Light/gentleman-light-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gentlement-ExLight';
  src: url('./fonts/Gentleman/ExLight/gentleman-300-extralight-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gentleman/ExLight/gentleman-300-extralight-webfont.woff2') format('woff2'), /* Super Modern Browsers */
      url('./fonts/Gentleman/ExLight/gentleman-300-extralight-webfont.woff') format('woff'), /* Pretty Modern Browsers */
      url('./fonts/Gentleman/ExLight/gentleman-300-extralight-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
      url('./fonts/Gentleman/ExLight/gentleman-300-extralight-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}


main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

p {
  margin: 0;
  padding: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea
{
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}


[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
button {
  cursor: revert;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

input,
textarea {
  user-select: auto;
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

body.notshow {
  overflow: hidden;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  line-break: after-white-space;
  -webkit-line-break: after-white-space;
  user-select: auto;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

li::marker{
  display: none !important;
  visibility: hidden !important;
  list-style: none;
}

.accordion {
  font-family: 'Gentlement-Light';
}

.accordion__item {
  margin-bottom: 0.8em;
}

.accordion__button {
  background-color: #DCE5EA;
  color: #002C40;
  cursor: pointer;
  padding: 1.5em 8em;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #DCE5EA;
}

/* 
.accordion__button::after {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  float: right;
  right: -80px;
  bottom: 30px;
} */

.accordion_arrow {
  position: relative;
  display: block;
  height: 60px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../src/images/icon/arrow-down-blue.png);
  float: right;
  right: -80px;
  bottom: 50px;
}

.accordion_arrow_up {
  position: relative;
  display: block;
  height: 60px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../src/images/icon/arrow-up-blue.png);
  float: right;
  right: -80px;
  bottom: 50px;
  cursor: pointer;
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after,
.accordion__button[aria-expanded='true']>.accordion_arrow,
.accordion__button[aria-selected='true']>.accordion_arrow {
  display: none;
}

[hidden],
.hidden {
  display: none;
}

.accordion__panel {
  background-color: #DCE5EA;
  width: 100%;
  padding: 1.5em 8em;
}

.swiper {
  height: 90vh;
}

/* .swiper-slide {
  height: 100vh;
} */

.swiper-button-next,
.swiper-button-prev {
  color: #FFF !important;
}

.swiper-pagination-bullet {
  border: 2px solid #FFFFFF;
  background: transparent;
}

.swiper-pagination-bullet-active {
  background: #FFF !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 50px !important;
  left: auto;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 50px !important;
  right: auto;
}

.bm-menu-wrap{
  height: auto !important;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-burger-button {
  position: fixed;
  height: 30px;
  width: 40px;
  top: 3em;
  left: 3em;
  background-image: url(../src/images/icon/menu-blue.png);
  background-repeat: no-repeat;
  background-size: contain;

}

.bm-cross {
  background: transparent;
}

/* General sidebar styles */
.bm-menu {
  background: #002C40;
  padding: 2.5em 0;
  font-size: 1.15em;
  height: auto !important;
  width: 320px;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: transparent !important;
}

.menu-item {
  padding: 0.4em 0;
  margin: 0 auto;
  text-align: center;
  color: #FFF;
  font-family: 'Gentlement-Reg';
  font-size: 24px;
  cursor: pointer;
}

.divTable {
  display: table;
  width: 100%;
  height: auto;
  margin-bottom: 1em;
}

.divTableBody {
  display: inline-table;
  width: 100%;
  
}

.divTableRow {
  display: table-row;
  width: 100%;
}

.divTableCell {
  display: table-cell;
  width: 50%;
  height: 100%;
  font-family: 'Gentlement-Reg';
}

.divTableCell h3 {
  color: #FFF;
}

.blue {
  background: #002C40;
  width: 35%;
}

.grey {
  background: #C7CFDC;
  padding: 0 0 0 0;
  
}

.grey:last-of-type{
  padding-bottom: 1em;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.divTableCell .tableHeader h3 {
  display: inline-flex;
  align-items: center;
  margin-left: 1.8em;
  margin-top: 1em;
  font-size: 20px;
}

.divTableCell ul {
  position: relative;
  margin-left: 3em;
  top: -0.1em;
  font-size: 18px;

}

.divTableCell ul li{
   margin: .1em 0;
   line-height: 1.6;
}

.divTableCell ul li::marker{
  display: none;
}

.divTableCell .TableChild {
  display: inline-table;
}

.divTableCell .TableChild h4 {
  position: relative;
  margin: 1em 0 0.5em 2.75em;
  font-size: 20px;
}

.popCopy{
  font-family: 'Gentlement-Reg';
  padding: 0.5em 1em;
  margin-bottom: 5em;
}

@media (max-width: 768px) {

  .bm-menu-wrap{
    height: auto !important;
    width: 200px !important;
  }

  .bm-burger-button {
    top: 2em;
    left: 2em;
    height: 25px;
    width: 35px;
  }

  .bm-menu {
    width: 200px;
  }

  .menu-item {
    font-size: 16px;
  }

  .swiper {
    height: 50vh;
  }

  .swiper-slide {
    height: 50vh;
  }


  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 2em !important;
    left: auto;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 2em !important;
    right: auto;
  }

  .accordion__button,
  .accordion__panel {
    padding: 0.5em 0.5em;
  }

  .accordion_arrow,
  .accordion_arrow_up {
    right: 0;
    height: 30px;
    width: 20px;
  }

  .accordion_arrow{
    bottom: 30px;
  }

  .divTableCell ul{
    margin-left: 1.5em;
    margin-right: 1em;
  }

  .divTableCell ul li{
    font-size: 12.5px;
  }

  .divTableCell .tableHeader h3{
    font-size: 16px;
    margin-left: 0.85em;
  }

  .blue{
    width: 50%;
  }

  .popCopy{
    padding: 0.5em 0em;
    margin-bottom: 0em;
    font-size: 13.5px;
  }

  .divTableCell .TableChild h4{
    margin: 1em 0 0.5em 0.5em;
    font-size: 16px;
  }

  .bg-mobile-left{
    background-position: left !important;
  }

}

@media (max-width: 768px) and (orientation:landscape){
  .swiper {
    height: 85vh;
  }
  
}

@media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
  .accordion_arrow {
    right: -40px;
  }
  .accordion_arrow_up {
    right: -40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
  .accordion_arrow {
    right: -40px;
  }
  .accordion_arrow_up {
    right: -40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
  .swiper {
    height: 75vh;
  }
}

@media (min-width: 820px) and (max-width: 1200px) and (orientation:portrait) {
  .swiper {
    height: 75vh;
  }
}